import {
  Box,
  Card,
  CardContent,
  CardOverflow,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from '@mui/joy';
import { isNil } from 'lodash/fp';
import { bignumber } from 'mathjs';
import { type FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router';

import { GraphQLErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage.tsx';
import HeaderBar from 'components/technical/HeaderBar/HeaderBar';
import KeyValueListSkeleton from 'components/technical/KeyValueListSkeleton';
import { portfolioRiskLink } from 'components/technical/Sidebar/Menu';
import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import CardWithFooter from './CardWithFooter';
import { DASHBOARD_CARD_HEIGHT } from './PortfolioDashboard.types.ts';
import PortfolioOpenDerivativePositionList from './PortfolioOpenDerivativePositionList';
import PortfolioOpenSpotPositionList, { type PositionDetails } from './PortfolioOpenSpotPositionList';
import PortfolioRealizedMetrics from './PortfolioRealizedMetrics';
import SubAccountBalanceList from './VenueBalanceList.tsx';
import {
  type IAsset,
  type IMetricCalculatorInput,
  usePortfolioExposureRiskQuery,
  usePortfolioMetricsQuery,
} from '../../../generated/graphql';
import { formatCash } from '../../formatter.utils';
import { isAssetLabelInput } from '../../market/asset/AssetLabelService';
import {
  PORTFOLIO_BETA_METRIC,
  PORTFOLIO_SHARPE_METRIC,
  PORTFOLIO_SORTINO_METRIC,
  PORTFOLIO_VOLATILITY_METRIC,
  annualized,
  benchmarkParameter,
  expanding,
  notAnnualized,
  realized,
} from '../../metrics/PortfolioMetricsData';
import type { SpotSubAccountSnapshot } from '../Portfolio.types';

const SubtitleWithHeading: FunctionComponent<{ subtitle: string; heading: string }> = ({ subtitle, heading }) => {
  return (
    <Stack>
      <Typography level="title-xs" component="h3">
        {subtitle}
      </Typography>
      <Typography level="h2" component="span">
        {heading}
      </Typography>
    </Stack>
  );
};

const ExposureRiskSkeleton: FunctionComponent<{ animation: boolean }> = ({ animation }) => {
  const animationProp = animation ? 'pulse' : false;
  return (
    <Card sx={{ height: DASHBOARD_CARD_HEIGHT, justifyContent: 'space-between' }}>
      <Stack height="100%" gap={3}>
        <Stack>
          <Skeleton level="h2" variant="text" width="50%" animation={animationProp} />
          <Skeleton level="h2" variant="text" width="30%" animation={animationProp} />
          <Skeleton level="title-sm" variant="text" width="60%" animation={animationProp} />
        </Stack>
        <Stack gap={2}>
          <Skeleton level="body-xs2" variant="text" width="25%" animation={animationProp} />
          <KeyValueListSkeleton animation={animation} />
        </Stack>
      </Stack>
      <CardOverflow variant="soft">
        <Divider inset="context" />
        <CardContent orientation="horizontal">
          <Skeleton level="body-xs2" sx={{ marginLeft: 'auto' }} variant="text" width="25%" animation={animationProp} />
        </CardContent>
      </CardOverflow>
    </Card>
  );
};

const metrics = (ethId: string, btcId: string): Array<IMetricCalculatorInput> => [
  {
    label: PORTFOLIO_SORTINO_METRIC,
    parameters: [realized, expanding, annualized],
  },
  {
    label: PORTFOLIO_SHARPE_METRIC,
    parameters: [realized, expanding, annualized],
  },
  {
    label: PORTFOLIO_VOLATILITY_METRIC,
    parameters: [realized, expanding, notAnnualized],
  },
  {
    label: PORTFOLIO_BETA_METRIC,
    parameters: [realized, expanding, benchmarkParameter(ethId)],
  },
  {
    label: PORTFOLIO_BETA_METRIC,
    parameters: [realized, expanding, benchmarkParameter(btcId)],
  },
];

const POSITIONS_GRID_HEIGHT = 350;

const ExposureRiskTitle = 'Exposures and risk';
const SpotsTab = 0;
const DerivativesTab = 1;
const SpotsUrl = '/app/portfolio/positions/spot';
const DerivativesUrl = '/app/portfolio/positions/derivatives';
const CounterpartyReportUrl = '/app/portfolio/risk/counterparty-report';

const PortfolioExposureRisk: FunctionComponent<{ assets: Pick<IAsset, 'id' | 'symbol'>[] }> = ({ assets }) => {
  const ethId = assets.find((asset) => asset.symbol === 'ETH')!.id;
  const btcId = assets.find((asset) => asset.symbol === 'BTC')!.id;

  const navigate = useNavigate();
  const { subAccountAssetFilters } = useSubAccountAssetFilters();
  const [selectedPositionsTab, setSelectedPositionsTab] = useState(SpotsTab);

  const { loading, data, error } = usePortfolioExposureRiskQuery({
    variables: {
      subAccountAssetFilters,
    },
  });

  const {
    loading: loadingRiskMetrics,
    data: riskMetricsData,
    error: riskMetricsError,
  } = usePortfolioMetricsQuery({
    variables: {
      input: {
        metrics: metrics(ethId, btcId),
        subAccountAssetFilter: subAccountAssetFilters,
      },
    },
  });

  if (loading || !isNil(error) || !data) {
    return (
      <Stack gap={1.5}>
        <HeaderBar title={ExposureRiskTitle} />
        {error && <GraphQLErrorMessage error={error} />}
        <Grid container alignItems="stretch" spacing={1.5}>
          <Grid xs={12} md={3}>
            <ExposureRiskSkeleton animation={loading} />
          </Grid>
          <Grid xs={12} md={6}>
            <ExposureRiskSkeleton animation={loading} />
          </Grid>
          <Grid xs={12} md={3}>
            <ExposureRiskSkeleton animation={loading} />
          </Grid>
        </Grid>
      </Stack>
    );
  }

  const portfolio = data.portfolio;
  const spotPositions: SpotSubAccountSnapshot[] = portfolio.positions.positions.filter(
    (pos): pos is SpotSubAccountSnapshot => !isNil(pos.spot) && isAssetLabelInput(pos.asset)
  );

  const costBasisPerAsset = new Map(
    portfolio.journal.costBasis.map((costBasis) => [costBasis.asset.id, bignumber(costBasis.costBasis)])
  );

  const positionDetails: PositionDetails[] = spotPositions.map((pos) => {
    return {
      ...pos,
      ...pos.spot,
    };
  });

  const openPositionsSummary = portfolio.positions.summary;
  const spotsDerivativesSeeMoreUrl = selectedPositionsTab === SpotsTab ? SpotsUrl : DerivativesUrl;

  const portfolioBalance = bignumber(openPositionsSummary?.balance.total ?? '0');

  return (
    <Stack gap={1.5}>
      <HeaderBar title={ExposureRiskTitle} />
      <Grid container alignItems="stretch" spacing={1.5}>
        <Grid xs={12} md={3}>
          <CardWithFooter seeMoreAction={(): void => navigate(portfolioRiskLink)}>
            <Stack gap={2} minHeight={0}>
              {openPositionsSummary && (
                <>
                  <SubtitleWithHeading
                    subtitle="Gross exposure"
                    heading={formatCash(
                      bignumber(openPositionsSummary.exposure.long).plus(openPositionsSummary.exposure.short)
                    )}
                  />
                  <SubtitleWithHeading
                    subtitle="Net exposure"
                    heading={formatCash(openPositionsSummary.exposure.net)}
                  />
                </>
              )}
              <Stack minHeight={0}>
                <Typography>Portfolio realized risk metrics</Typography>
                {loadingRiskMetrics && <KeyValueListSkeleton animation />}
                {riskMetricsError && <GraphQLErrorMessage error={riskMetricsError} />}
                {riskMetricsData && (
                  <PortfolioRealizedMetrics
                    metrics={riskMetricsData.portfolio.metrics}
                    betaAssetIds={{ btc: btcId, eth: ethId }}
                  />
                )}
              </Stack>
            </Stack>
          </CardWithFooter>
        </Grid>
        <Grid xs={12} md={6}>
          <CardWithFooter seeMoreAction={(): void => navigate(spotsDerivativesSeeMoreUrl)}>
            <Stack gap={2} height="100%">
              <Typography level="h3">Open positions</Typography>
              <Tabs
                sx={{ height: '100%' }}
                value={selectedPositionsTab}
                onChange={(_, value): void => setSelectedPositionsTab(value as number)}
              >
                <TabList>
                  <Tab>Spot</Tab>
                  <Tab>Derivatives</Tab>
                </TabList>
                <TabPanel value={SpotsTab} sx={{ height: POSITIONS_GRID_HEIGHT, overflow: 'auto' }}>
                  <PortfolioOpenSpotPositionList
                    positions={positionDetails}
                    costBasisPerAsset={costBasisPerAsset}
                    portfolioBalance={portfolioBalance}
                  />
                </TabPanel>
                <TabPanel value={DerivativesTab} sx={{ height: POSITIONS_GRID_HEIGHT, overflow: 'auto' }}>
                  <PortfolioOpenDerivativePositionList data={data} portfolioBalance={portfolioBalance} />
                </TabPanel>
              </Tabs>
            </Stack>
          </CardWithFooter>
        </Grid>
        <Grid xs={12} md={3}>
          <CardWithFooter seeMoreAction={(): void => navigate(CounterpartyReportUrl)}>
            <Stack gap={2} height="100%">
              <Typography level="h3" component="span">
                Counterparty risk
              </Typography>
              {/* height: 0 force vertical scroll and fix aligment, but 0 doesn't work on mobile */}
              <Box sx={{ overflow: 'auto', height: { xs: undefined, sm: 0 }, flexGrow: 1 }}>
                <SubAccountBalanceList balanceByVenue={openPositionsSummary.balanceByVenue} />
              </Box>
            </Stack>
          </CardWithFooter>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default PortfolioExposureRisk;
