import type { ValueGetterParams } from 'ag-grid-community';
import type { FunctionComponent } from 'react';
import { formatPercentage } from 'components/formatter.utils';
import { UserSettings } from 'components/management/UserSettings.types';
import GAgGridPresets from 'components/technical/grids/GAgGridPresets';
import type { IRealizedPnlAnalysisQuery } from 'generated/graphql';
import {
  aggregateFeeVolume,
  aggregateLossesAndWins,
  calculateWinLossRatio,
  calculateWinPercentage,
  decimalAggregate,
  type FeeAndVolume,
  feeVolumeGetter,
  winLossCalculator,
  winLossGetter,
} from './calculations.ts';
import { realizedPnlDefaultPresets } from './realizedPnlDefaultPresets.ts';
import {
  accountColumn,
  nameColumn,
  subAccountColumn,
  subFundGroupColumn,
  symbolColumn,
  underlyingAssetColumn,
  venueColumn,
  yearMonthDayColumns,
} from 'components/technical/grids/SharedReportColumns';
import { mapSubAccountAndDimensionToSubFund } from 'components/bookkeeping/report/Report.utils.tsx';
import { type BigNumber, bignumber } from 'mathjs';

type RowData = IRealizedPnlAnalysisQuery['portfolio']['realizedPnl'][number];
type SubFund = IRealizedPnlAnalysisQuery['portfolio']['subFunds']['list'][number];

type RealizedPnlGridProps = {
  rows: RowData[];
  subFunds: SubFund[];
};

const reportGridHeight = '85vh'; // give almost all space to the grid

const RealizedPnlGrid: FunctionComponent<RealizedPnlGridProps> = ({ rows, subFunds }) => {
  const { subAccountAndDimensionToSubFund, subFundDimensions } = mapSubAccountAndDimensionToSubFund(subFunds);

  return (
    <GAgGridPresets<RowData>
      presetSettingsKey={UserSettings.RealizedPnlPresets}
      defaultPresets={realizedPnlDefaultPresets}
      rowData={rows}
      sideBar={{
        toolPanels: ['columns', 'filters'],
      }}
      suppressAggFuncInHeader
      enableCharts
      enableRangeSelection
      height={reportGridHeight}
      defaultColDef={{
        resizable: true,
        sortable: true,
        filter: true,
      }}
      columnDefs={[
        {
          headerName: 'Asset Details',
          colId: 'asset-details',
          marryChildren: true,

          children: [nameColumn(), symbolColumn(), underlyingAssetColumn()],
        },
        {
          headerName: 'Account Details',
          colId: 'account-details',
          marryChildren: true,
          children: [accountColumn(), subAccountColumn(), venueColumn()],
        },
        {
          headerName: 'Sub-funds',
          colId: 'sub-funds',
          hide: true,
          marryChildren: true,
          children: subFundDimensions.map((subFundDimension) =>
            subFundGroupColumn(subFundDimension, subAccountAndDimensionToSubFund)
          ),
        },
        yearMonthDayColumns<RowData>({
          field: 'executionDay',
          show: {
            day: true,
          },
        }),
        {
          headerName: 'Total win',
          colId: 'total-win',
          field: 'totalWin',
          type: ['numericColumn', 'cashColumn'],
          aggFunc: decimalAggregate,
        },
        {
          headerName: 'Total loss',
          colId: 'total-loss',
          field: 'totalLoss',
          type: ['numericColumn', 'cashColumn'],
          aggFunc: decimalAggregate,
        },
        {
          headerName: 'Net',
          colId: 'net',
          valueGetter: (params: ValueGetterParams<RowData>): BigNumber => {
            const wins = bignumber(params.data?.totalWin ?? '0');
            const losses = bignumber(params.data?.totalLoss ?? '0');
            return wins.sub(losses);
          },

          type: ['numericColumn', 'cashColumn'],
          aggFunc: decimalAggregate,
        },
        {
          headerName: '# of wins',
          colId: 'n-wins',
          field: 'numberOfWins',
          type: ['numericColumn'],
          aggFunc: 'sum',
        },
        {
          headerName: '# of losses',
          colId: 'n-losses',
          field: 'numberOfLosses',
          type: ['numericColumn'],
          aggFunc: 'sum',
        },
        {
          headerName: 'Total volume [units]',
          colId: 'total-volume',
          field: 'totalVolume',
          type: ['numericColumn'],

          aggFunc: decimalAggregate,
        },
        {
          headerName: 'Total volume [usd]',
          colId: 'total-volume-usd',
          field: 'totalVolumeUsd',
          type: ['numericColumn', 'cashColumn'],
          aggFunc: decimalAggregate,
        },
        {
          headerName: 'Win percentage',
          colId: 'win-percentage',
          valueGetter: winLossGetter,
          valueFormatter: winLossCalculator(calculateWinPercentage),
          aggFunc: aggregateLossesAndWins,
          type: ['numericColumn'],
        },

        {
          headerName: 'Win/Loss ratio',
          colId: 'win-loss-ratio',
          valueGetter: winLossGetter,
          valueFormatter: winLossCalculator(calculateWinLossRatio),
          aggFunc: aggregateLossesAndWins,
          type: ['numericColumn'],
        },
        {
          headerName: 'Fee [usd]',
          colId: 'fee',
          field: 'totalFees',
          type: ['numericColumn', 'cashColumn'],
          aggFunc: decimalAggregate,
        },
        {
          headerName: 'Fee / volume',
          colId: 'fee-volume',
          valueGetter: feeVolumeGetter,
          valueFormatter: ({ value }: { value: FeeAndVolume }): string => {
            if (!value) {
              return '';
            }
            const percentage = value.fee.div(value.volume);
            if (percentage.isNaN() || !percentage.isFinite()) {
              return '-';
            }
            return formatPercentage(percentage);
          },

          aggFunc: aggregateFeeVolume,
        },
      ]}
    />
  );
};

export default RealizedPnlGrid;
